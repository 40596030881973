import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Map from "../google-maps";
import AdSpaceMapCard from "../../containers/google-maps/AdSpaceListCard";
import CloseIcon from "../../icons/close-icon";
import { getSpacePriceConfigFromSpot, getAdSpacePriceFromConfig } from "../../utils/spots";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1180,
    padding: 0,
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  closeIcon: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    cursor: "pointer",
  },
}));

function MapPopup({ open = false, center = [-1, 1], adSpaces = [], onClose = () => {} }) {
  const classes = useStyles(theme);

  const currentAsset = useSelector((state) => state.misc.currentAsset);
  const spots = useSelector((state) =>
    currentAsset && parseInt(currentAsset.id, 10) ? state.misc.spots[parseInt(currentAsset.id, 10)] ?? [] : [],
  );
  const spot = (typeof localStorage !== "undefined" && localStorage.getItem("AssetSpotCardSelected")) || null;
  const selectedSpot = spots.find((s) => s.slug === spot);
  const priceConfig = selectedSpot?.data?.price ?? getSpacePriceConfigFromSpot(spot);

  const [priceMarkers, setPriceMarkers] = useState([]);

  useEffect(() => {
    const priceMrks = adSpaces.map((as) => {
      return {
        latitude: as.latitude,
        longitude: as.longitude,
        display_price: getAdSpacePriceFromConfig(as, priceConfig),
        id: as.id,
      };
    });
    setPriceMarkers(priceMrks);
  }, [adSpaces]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableScrollLock
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(2),
          width: "1100px",
          maxWidth: "1100px",
          height: "500px",
        },
      }}
    >
      <Box className={classes.closeIcon} onClick={onClose}>
        <CloseIcon width="12px" height="12px" />
      </Box>
      <Box mt={2}>
        <Map
          MapCard={AdSpaceMapCard}
          height="425px"
          width="100%"
          center={center}
          priceMarkers={priceMarkers}
          zoom={18}
          isAdSpace
        />
      </Box>
    </Dialog>
  );
}

export default MapPopup;
