import React, { useState, useEffect } from "react";
import { useParams } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import {
  getAssetsThunk,
  setCurrentAsset,
  resetCurrentAsset,
  getFeatureFlagsThunk,
  getSpotsByAssetThunk,
} from "../../redux/actions/misc-actions";
import {
  setAdSpaceConsultation,
  getAdSpaceConsultUnavailableDatesThunk,
  resetAdSpaceConsultation,
  setAdSpacesConsultationDates,
  getAdSpacesCategoriesThunk,
  setAdSpaceConsultationCategory,
  resetAdSpaceConsultationCategory,
} from "../../redux/actions/adspaces-actions";
import AdSpacesComponent from "../../components/asset-advertising";
import api from "../../api";
import { useNavigate } from "/src/hooks/navigation";
import { useQuery } from "../../utils";
import { formatDateUtc, isDateValid } from "../../utils/dateUtils";

function AdSpaces() {
  const params = useParams();
  const dispatch = useDispatch();
  const { navigate } = useNavigate();

  const region = useSelector((state) => state.region.region);
  const flagAdspaceCategories = useSelector((state) => state.misc.featureFlags["flag.ops.adspace_categories"]);

  const assets = useSelector((state) => state.misc.assets);
  const spots = useSelector((state) =>
    parseInt(params.assetId, 10) ? state.misc.spots[parseInt(params.assetId, 10)] ?? [] : [],
  );

  const spot = (typeof localStorage !== "undefined" && localStorage.getItem("AssetSpotCardSelected")) || null;

  const adSpacesConsultation = useSelector((state) => state.adspaces.adSpacesConsultation);
  const adSpacesConsultationDates = useSelector((state) => state.adspaces.adSpacesConsultationDates);
  const adSpacesConsultationCategory = useSelector((state) => state.adspaces.adSpacesConsultationCategory);
  const adSpacesCategories = useSelector((state) => state.adspaces.adSpacesCategories);

  const query = useQuery();

  const startDate = query.get("start");
  const endDate = query.get("end");

  const [asset, setAsset] = useState();
  const [adSpaces, setAdSpaces] = useState();

  const setupAdSpaces = (asset) => {
    if (asset) {
      setAsset(asset);
      dispatch(dispatch(resetAdSpaceConsultation()));
      dispatch(dispatch(resetAdSpaceConsultationCategory()));
      if (isDateValid(startDate) && isDateValid(endDate)) {
        dispatch(setAdSpacesConsultationDates([new Date(startDate), new Date(endDate)]));
      }
      dispatch(setCurrentAsset(asset));

      api.getAdSpaces({ asset: asset.id, sort: null }).then((res) => {
        if (res.status === 200) {
          setAdSpaces(res.data?.payload?.data?.map((ad) => ({ ...ad, isAvailable: true })));
        } else {
          setAdSpaces([]);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getFeatureFlagsThunk());
    dispatch(getAssetsThunk());
    dispatch(getSpotsByAssetThunk(params.assetId));
    return () => {
      dispatch(resetCurrentAsset());
    };
  }, []);

  useEffect(() => {
    if (flagAdspaceCategories) {
      dispatch(getAdSpacesCategoriesThunk());
    }
  }, [flagAdspaceCategories]);

  useEffect(() => {
    if (asset) {
      getAdSpacesByDates();
    }
  }, [adSpacesConsultationDates, adSpacesConsultationCategory]);

  useEffect(() => {
    const asset = assets.find((a) => a.id === parseInt(params.assetId));

    setupAdSpaces(asset);
  }, [assets]);

  const handleChangeAsset = (asset) => {
    navigate(`/active-adspaces/${asset.id}`, { replace: true });
    setTimeout(() => {
      window.location.href = window.location.pathname;
    }, 1);
  };

  const handleChangeAdSpaceCategory = (category) => {
    dispatch(setAdSpaceConsultationCategory(category ? category.slug : ""));
  };

  const getAdSpacesByDates = async () => {
    const filteredData = await api.getAdSpaces({
      asset: asset.id,
      filters: {
        start: adSpacesConsultationDates[0] ? formatDateUtc(adSpacesConsultationDates[0], "YYYY-MM-DD") : "",
        end: adSpacesConsultationDates[1] ? formatDateUtc(adSpacesConsultationDates[1], "YYYY-MM-DD") : "",
        tag: adSpacesConsultationCategory,
      },
      sort: null,
    });
    if (filteredData.status === 200) {
      setAdSpaces(
        filteredData.data?.payload?.data.map((ad) => ({
          ...ad,
          isAvailable: true,
        })),
      );
    } else {
      setAdSpaces([]);
    }
  };

  const setSelectedAdSpaces = (adSpace) => {
    let currentAdSpacesConsultation = [];
    if (adSpacesConsultation.some((ads) => ads.id === adSpace.id)) {
      currentAdSpacesConsultation = [...adSpacesConsultation.filter((ads) => ads.id !== adSpace.id)];
    } else {
      currentAdSpacesConsultation = [...adSpacesConsultation, adSpace];
    }
    dispatch(setAdSpaceConsultation(currentAdSpacesConsultation));
    dispatch(getAdSpaceConsultUnavailableDatesThunk(currentAdSpacesConsultation));
  };

  const handleShowAllAdSpaces = async (showAll) => {
    if (asset) {
      if (showAll && isDateValid(adSpacesConsultationDates[0]) && isDateValid(adSpacesConsultationDates[1])) {
        let adSpaces = [];
        let adSpacesFiltered = [];
        const data = await api.getAdSpaces({ asset: asset.id, sort });
        const filteredData = await api.getAdSpaces({
          asset: asset.id,
          filters: {
            start: formatDateUtc(adSpacesConsultationDates[0], "YYYY-MM-DD"),
            end: formatDateUtc(adSpacesConsultationDates[1], "YYYY-MM-DD"),
          },
          sort: null,
        });
        if (data.status === 200 && filteredData.status === 200) {
          adSpaces = data.data?.payload?.data;
          adSpacesFiltered = filteredData.data?.payload?.data;
          setAdSpaces(
            adSpaces.map((ad) => {
              if (adSpacesFiltered.some((fad) => ad.id === fad.id)) {
                return { ...ad, isAvailable: true };
              }
              return { ...ad, isAvailable: false };
            }),
          );
        }
      } else {
        getAdSpacesByDates();
      }
    }
  };

  return (
    <AdSpacesComponent
      region={region}
      showAdspaceCategories={flagAdspaceCategories}
      asset={asset}
      spots={spots}
      spot={spot}
      category={adSpacesConsultationCategory}
      assets={assets}
      onChangeAsset={handleChangeAsset}
      onChangeAdSpaceCategory={handleChangeAdSpaceCategory}
      adSpaces={adSpaces}
      adSpacesCategories={adSpacesCategories}
      adSpacesConsultation={adSpacesConsultation}
      showAllAdSpaces={handleShowAllAdSpaces}
      setSelectedAdSpaces={setSelectedAdSpaces}
    />
  );
}

export default AdSpaces;
