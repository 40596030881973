import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAdSpaceConsultation, getAdSpaceConsultUnavailableDatesThunk } from "../../redux/actions/adspaces-actions";
import SelectedsAdSpaceModalComponent from "../../components/asset-advertising/remove-selecteds-ad-space-modal";

function SelectedsAdSpaceModal({ open, onClose }) {
  const dispatch = useDispatch();

  const adSpacesConsultation = useSelector((state) => state.adspaces.adSpacesConsultation);

  const currentAsset = useSelector((state) => state.misc.currentAsset);
  const spots = useSelector((state) =>
    currentAsset && parseInt(currentAsset.id, 10) ? state.misc.spots[parseInt(currentAsset.id, 10)] ?? [] : [],
  );
  const spot = (typeof localStorage !== "undefined" && localStorage.getItem("AssetSpotCardSelected")) || null;

  const removeSelectedAdSpaces = (adSpace) => {
    const currentAdSpacesConsultation = adSpacesConsultation.filter((ad) => ad.id !== adSpace.id);
    dispatch(setAdSpaceConsultation(currentAdSpacesConsultation));
    dispatch(getAdSpaceConsultUnavailableDatesThunk(currentAdSpacesConsultation));
  };

  return (
    <SelectedsAdSpaceModalComponent
      open={open}
      onClose={onClose}
      spots={spots}
      spot={spot}
      adSpacesConsultation={adSpacesConsultation}
      removeSelectedAdSpaces={removeSelectedAdSpaces}
    />
  );
}

export default SelectedsAdSpaceModal;
