import React from "react";
import Calendar from "../../components/calendar";
import { formatDateUtc } from "../../utils/dateUtils";

const SpaceAvailableDatesCalendar = ({
  startDate = "",
  endDate = "",
  onChange = () => {},
  adSpacesConsultationUnavailableDates = {},
  calendarProps = {},
}) => {
  const handleIsTileDisabled = ({ date }) => {
    const formattedDate = formatDateUtc(date, "YYYY-MM-DD");
    return adSpacesConsultationUnavailableDates[formattedDate] !== undefined;
  };

  return (
    <Calendar
      tileDisabled={handleIsTileDisabled}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      isDouble={false}
      {...calendarProps}
    />
  );
};

export default SpaceAvailableDatesCalendar;
