import React, { useState, useEffect } from "react";
import { useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { setAdSpacesConsultationDates } from "../../redux/actions/adspaces-actions";
import { formatDateUtc, dateDiff, add, isDateValid } from "../../utils/dateUtils";
import { useNavigate } from "/src/hooks/navigation";
import SideMenuComponent from "../../components/asset-advertising/side-menu";

function SideMenu({ ...rest }) {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();
  const adSpacesConsultation = useSelector((state) => state.adspaces.adSpacesConsultation);
  const adSpacesConsultationDates = useSelector((state) => state.adspaces.adSpacesConsultationDates);
  const adSpacesConsultationUnavailableDates = useSelector(
    (state) => state.adspaces.adSpacesConsultationUnavailableDates,
  );
  const assets = useSelector((state) => state.misc.assets);

  const [asset, setAsset] = useState();

  useEffect(() => {
    const asset = assets.find((a) => a.id === parseInt(params.assetId, 10));

    if (asset) {
      setAsset(asset);
    }
  }, [assets]);

  const handleOnDateChange = (dates) => {
    const start = dates[0];
    const end = dates[1];
    const diff = dateDiff(start, end);
    let canSelectDate = true;

    for (let i = 0; i <= diff; i++) {
      const date = add(start, i, "day").format("YYYY-MM-DD");
      if (adSpacesConsultationUnavailableDates[date]) {
        canSelectDate = false;
      }
    }

    if (canSelectDate) {
      dispatch(setAdSpacesConsultationDates(dates));
    } else {
      dispatch(setAdSpacesConsultationDates([]));
    }
  };

  const handleAsk = () => {
    const isStartDateValid = isDateValid(adSpacesConsultationDates[0]);
    const isEndDateValid = isDateValid(adSpacesConsultationDates[1]);
    const isFormValid = isStartDateValid && isEndDateValid;

    if (isFormValid) {
      const searchParams = `&end=${formatDateUtc(adSpacesConsultationDates[1], "YYYY-MM-DD")}`;

      navigate(
        `/consult-adspace/${asset.id}?start=${formatDateUtc(
          adSpacesConsultationDates[0],
          "YYYY-MM-DD",
        )}&adspaces=${adSpacesConsultation.map((ad) => ad.id).join(",")}${searchParams}`,
      );
    }
  };

  return (
    <SideMenuComponent
      adSpacesSelecteds={adSpacesConsultation}
      adSpacesConsultationUnavailableDates={adSpacesConsultationUnavailableDates}
      dates={adSpacesConsultationDates}
      onDateChange={handleOnDateChange}
      handleAsk={handleAsk}
      {...rest}
    />
  );
}

export default SideMenu;
